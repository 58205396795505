import { Box } from "@mui/material";
import Steps from "./Pages/Steps";

const styles = {
  paperContainer: {
      height: '100%',
      width: '100%',
      backgroundImage: `url(${"/jaspos/jas5bg2.jpg"})`,
  }
};

function App() {
  return (
    <>
    <Box style={styles.paperContainer}>
      <Steps />
    </Box>
    </>
  );
}

export default App;
