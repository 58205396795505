import React from 'react'
import { Card, Accordion, AccordionSummary, AccordionDetails, Typography, Stack, Container, Button } from '@mui/material';
import { Image } from 'mui-image'

const Category = () => {
  return (
    <>
      <Container >
        <Accordion sx={{color: '#05805f'}}>
          <AccordionSummary
            expandIcon={<Image src='/expandmore.png' width={10} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>CLASSIFICATION: Adding Category</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* <Stack >
              <Image src='/steps/step-four.png' width={500} />
            </Stack> */}

            <Card sx={{p: 2}}>
              <Typography variant='h6'>
                {'1. Click the '}<Button variant='contained' color='success'>Add Category</Button>{' to open the form for adding Category.'}
              </Typography>

              <Typography variant='h6'>
                {'2. Provide the necessary information on the form provided.'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/classification1.jpg' width={500} />
                </Stack>
              </Typography>

              <Typography variant='h6'>
                {'3. Click the '}<Button variant='contained' color='success'>Add Category</Button> {' button to continue. There will be validation before you add the Category so you can check if the information provided is corrrect.'}
              </Typography>
            </Card>

          </AccordionDetails>
        </Accordion>

      </Container>
    </>
  )
}

export default Category