import React from 'react'
import { Divider, Button, Card, Accordion, AccordionSummary, AccordionDetails, Typography, Stack, Container } from '@mui/material';
import { Image } from 'mui-image'

const Inventory = () => {
  return (
    <>
      <Container >
        <Accordion sx={{color: '#05805f'}}>
          <AccordionSummary
            expandIcon={<Image src='/expandmore.png' width={10} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>INVENTORY: Adding Good Stocks and Detecting Bad Stocks</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* <Stack direction="row"
              justifyContent="flex-start"
              alignItems="flex-start">
              <Image src='/steps/step-inventory.png' width={500} />
            </Stack> */}

            <Card sx={{p: 2}}>

              <Typography variant='h6'>
                {'1. Click the '}<Button variant='contained' color='success'>Add/Update Inventory</Button> {' to open the form for adding Inventory.'}
              </Typography>

              <Typography variant='h6'>
                {'2. Provide the necessary information on the form provided.'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/addstocks1.jpg' width={500} />
                </Stack>
              </Typography>

              <Typography variant='h6'>
                {'3. Click the '}<Button variant='contained' color='success'>Confirm Add</Button> {' button to continue. There will be validation before you add the Inventory so you can check if the information provided is corrrect.'}
              </Typography>

              {/* <Typography variant='h6'>
                {'Note: You can also check Bad Stock sub-module to check the near expiry, Packaging Issues, and Product recall stocks.'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/badstocks1.jpg' width={500} />
                </Stack>
              </Typography> */}

              <Divider sx={{m: 3}}/>

              <Typography variant='h6'>
                {'Note: Under "Good Stock" module, once a stock is added it will be seen on the table. By clicking the kebab menu on the right side for the options to be shown. (Stock Recieved, Remove Stock and Set Alert Treshhold)'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/goodstock1.png' width={500} />
                </Stack>
              </Typography>

              <Typography variant='h6'>
                {'You can also check Bad Stock sub-module to check the near expiry, Packaging Issues, and Product recall stocks.'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/badstocks1.jpg' width={500} />
                </Stack>
              </Typography>

            </Card>

          </AccordionDetails>
        </Accordion>

      </Container>
    </>
  )
}

export default Inventory