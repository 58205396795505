import React from 'react'
import { Card, Accordion, AccordionSummary, AccordionDetails, Typography, Stack, Container } from '@mui/material';
import { Image } from 'mui-image'

const Invoice = () => {
  return (
    <>
      <Container >
        <Accordion sx={{color: '#05805f'}}>
          <AccordionSummary
            expandIcon={<Image src='/expandmore.png' width={10} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>ORDER MANAGEMENT: Sales Invoice</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* <Stack direction="column"
              justifyContent="center"
              alignItems="flex-start">
              <Image src='/jaspos/SI1.png' width={500} />
              <Image src='/jaspos/SI2.png' width={500} />
            </Stack> */}

            <Card sx={{p: 2}}>
              <Typography variant='h6'>
                {'1. To view the sales invoice, go to "Sales Invoice" module.'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/salesinvoice1.jpg' width={500} />
                </Stack>
              </Typography>

              <Typography variant='h6'>
                {'2. Click the "Three dot setting/Kebab Settings and you will see the following options. (View, Edit Email and Send Email)'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/salesinvoice2.jpg' width={500} />
                </Stack>
              </Typography>
              
              <Typography variant='h6'>
                {'3. Click "View" option to view the receipt. You can also print and export for your own copy.'}
              </Typography>
              
              <Typography variant='h6'>
                {'4. Click "Edit Email" option and fill up the form given. You can also click the "plus" button to input multiple email. Then Click "Save" to save the added email.'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/editbilling1.jpg' width={500} />
                </Stack>
              </Typography>

            </Card>


          </AccordionDetails>
        </Accordion>

      </Container>
    </>
  )
}

export default Invoice