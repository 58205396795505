import React from 'react'
import { Divider, Button, Card, Accordion, AccordionSummary, AccordionDetails, Typography, Stack, Container } from '@mui/material';
import { Image } from 'mui-image'

const OrderManagement = () => {
  return (
    <>
      <Container >
        <Accordion sx={{ color: '#05805f' }}>
          <AccordionSummary
            expandIcon={<Image src='/expandmore.png' width={10} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>ORDER MANAGEMENT: Adding a Quotation</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* <Stack direction="column"
              justifyContent="center"
              alignItems="flex-start">
              <Image src='/jaspos/om1.png' width={500} />
              <Image src='/jaspos/om2.png' width={500} />
            </Stack> */}

            <Card sx={{ p: 2 }}>

              <Typography variant='h6'>
                {'1. Click the '} <Button variant='contained' color='success' >Add New Quotation</Button>{' to open the form for adding new Quotation.'}
              </Typography>

              <Typography variant='h6'>
                {'2. Provide the necessary information on the form provided.'}
              </Typography>

              <Typography variant='h6'>
                {'3. Click the '}<Button variant='contained' color="error">Draft Quotation</Button> {' button to continue.'}
              </Typography>

              <Typography variant='h6'>
                {'4. You will see the "Draft" draft status in data table for validation'}
              </Typography>

              <Typography variant='h6'>
                {'5. To approve the Quotation, go to "Quotation Approval" module'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/quotationapproval1.jpg' width={500} />
                </Stack>
              </Typography>

              <Typography variant='h6'>
                {'6. Under the "Three dot setting/Kebab Settings then click the "Edit" button to continue.'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/ordermanagement1.jpg' width={500} />
                </Stack>
              </Typography>

              <Typography variant='h6'>
                {'7. You can modify the Delivery Date, Person In Charge, Quantity, discount type, total Discount type, Discount amount, Batch/Lot No. and you can also add another product.'}
              </Typography>

              <Typography variant='h6'>
                {'8. Click '}<Button variant='contained' color="success">Accept Quotation</Button>{' button to approve quotation.'}
              </Typography>

            </Card>
          </AccordionDetails>
        </Accordion>

      </Container>
    </>
  )
}

export default OrderManagement