import React from 'react'
import { Card, Accordion, AccordionSummary, AccordionDetails, Typography, Stack, Container, Button } from '@mui/material';
import { Image } from 'mui-image'

const POSO = () => {
  return (
    <>
      <Container>
        <Accordion sx={{color: '#05805f'}}>
          <AccordionSummary
            expandIcon={<Image src='/expandmore.png' width={10} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>ORDER MANAGEMENT: Purchase Order and Sales Order</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* <Stack direction="column"
              justifyContent="flex-start"
              alignItems="flex-start">
              <Image src='/jaspos/POSO1.png' width={500} />
              <Image src='/jaspos/POSO2.png' width={500} />
              <Image src='/jaspos/POSO3.png' width={500} />
            </Stack> */}

            <Card sx={{p: 2}}>
              <Typography variant='h6'>
                {'1. Go to PO/SO module to view the data table of the approved quotation.'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/po-so1.jpg' width={500} />
                </Stack>
              </Typography>

              <Typography variant='h6'>
                {'2.  Click the "Three dot setting/Kebab Settings there is view, edit and close.'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/po-so2.jpg' width={500} />
                </Stack>
              </Typography>

              <Typography variant='h6'>
                {'3. Click the "View" button, to see the preview of the reciept of the quotation selected. You can also print and export the table to have your own copy.'}
              </Typography>

              <Typography variant='h6'>
                {'4. Click "Edit" button to edit any information provided on the forms.'}
              </Typography>

              <Typography variant='h6'>
                {'5. Click '}<Button variant='contained' color='success'>Post To Stock Issuance</Button>  {' to post the quotation on stock issuance. The VAT in and Vat Ex will be separated once the quotation is posted.'}
              </Typography>

              <Typography variant='h6'>
                {'6. The "Close" button will enable if the Sales Order quantity did not meet the requirement.'}
              </Typography>

            </Card>

          </AccordionDetails>
        </Accordion>

      </Container>
    </>
  )
}

export default POSO