import React from 'react'
import { Card, Accordion, AccordionSummary, AccordionDetails, Typography, Stack, Container } from '@mui/material';
import { Image } from 'mui-image'

const Analytics = () => {
  return (
    <>
      <Container >
        <Accordion sx={{color: '#05805f'}}>
          <AccordionSummary
            expandIcon={<Image src='/expandmore.png' width={10} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Analytics Management: Sales Analytics</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* <Stack direction="column"
              justifyContent="center"
              alignItems="flex-start">
              <Image src='/jaspos/ana1.png' width={500} />
              <Image src='/jaspos/ana2.png' width={500} />
              <Image src='/jaspos/ana3.png' width={500} />
            </Stack> */}

            <Card sx={{p: 2}}>
              <Typography variant='h6'>
                {'1. To view the analytics, go to "Analytics Management" module.'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/analytics1.jpg' width={500} />
                </Stack>
              </Typography>

              <Typography variant='h6'>
                {'2. You can see the Weekly Sales, new products, Item, Orders and Order Pending quantities.'}
              </Typography>
              
              <Typography variant='h6'>
                {'3. You can also check the Sales Summary Chart by Clicking "Apply" after you select the date.'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/analytics2.jpg' width={500} />
                </Stack>
              </Typography>
              
              <Typography variant='h6'>
                {'4. The Sales Summary chart will show the count of Gross Sales, Net Sales, Gross Profit, Discount Total and Discount Total Per Product.'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/salessummary1.png' width={500} />
                </Stack>
              </Typography>
              
              <Typography variant='h6'>
                {'5. The Yearly Sales will show you the total Income and total Expenses on selected Year.'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/yearlysales1.png' width={500} />
                </Stack>
              </Typography>

              <Typography variant='h6'>
                {'6. You can also view the latest products added and Top products.'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/topproducts1.png' width={500} />
                </Stack>
              </Typography>

            </Card>

          </AccordionDetails>
        </Accordion>

      </Container>
    </>
  )
}

export default Analytics