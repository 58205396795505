import React from 'react'
import { Button, Box, Fab, Card, Accordion, AccordionSummary, AccordionDetails, Typography, Stack, Container } from '@mui/material';
import { Image } from 'mui-image'

const Login = () => {
  return (
      <Container >
        <Accordion sx={{color: '#05805f'}}>
          <AccordionSummary
            expandIcon={<Image src='/expandmore.png' width={10} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>SIGN IN: How to Log In</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* <Stack >
              <Image src='/steps/step-two.png' width={500} />
            </Stack> */}

            <Card sx={{p: 2}}>
              <Typography variant='h6'>
                {"1. Log in your Account. Type on your browser"}
              </Typography>

              <Box sx={{p: 2}}>
              <Fab href='https://jas5-pos.8nergyitsolutions.com'
                variant="extended" color='info'>
                  <Typography variant='subtitle1'>
                  {"https://jas5-pos.8nergyitsolutions.com"}
                </Typography>
              </Fab>
              </Box>

              <Typography variant='h6'>
                {"2. Type the necessary credentials provided."}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/login.png' width={500} />
                </Stack>
              </Typography>

              <Typography variant='h6'>
                {'3. Click the '} <Button variant='contained' color='success' sx={{width: 300}}>Login</Button> {'Button to continue.'}
              </Typography>

            </Card>

          </AccordionDetails>
        </Accordion>

      </Container>
  )
}

export default Login