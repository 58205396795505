import React from 'react'
import { Button, Card, Accordion, AccordionSummary, AccordionDetails, Typography, Stack, Container } from '@mui/material';
import { Image } from 'mui-image'

const Supplier = () => {
  return (
    <>
      <Container>
        <Accordion sx={{color: '#05805f'}}>
          <AccordionSummary
            expandIcon={<Image src='/expandmore.png' width={10} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>SUPPLIER: Adding Supplier</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* <Stack direction="row"
              justifyContent="flex-start"
              alignItems="flex-start">
              <Image src='/steps/step-supplier.png' width={500} />
            </Stack> */}

            <Card sx={{p: 2}}>
              <Typography variant='h6'>
                {'1. Click '}<Button variant='contained' color='success'>Add New Supplier</Button> {' to open the form for adding.'}
              </Typography>

              <Typography variant='h6'>
                {'2. Input necessary information on the form provided.'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/addsupp1.jpg' width={500} />
                </Stack>
              </Typography>
              
              <Typography variant='h6'>
                {'3. Click the '}<Button variant='contained' color='success'>Confirm Add</Button> {' button to continue. There will be validation before you add the supplier so you can check if the information provided is correct.'}
              </Typography>

            </Card>

          </AccordionDetails>
        </Accordion>

      </Container>
    </>
  )
}

export default Supplier