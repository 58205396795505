import React from 'react'
import { Stack } from '@mui/material';
import { Image } from 'mui-image'

const Header = () => {
    return (
        <div>
            <Stack direction="row"
                justifyContent="center"
                alignItems="center">
                <Image src='/jaspos.png' minwidth={700} />
            </Stack>
        </div>
    )
}

export default Header