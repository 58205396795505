import React from 'react'
import { Card, Accordion, AccordionSummary, AccordionDetails, Typography, Stack, Container } from '@mui/material';
import { Image } from 'mui-image'

const Audit = () => {
  return (
    <>
      <Container>
        <Accordion sx={{color: '#05805f'}}>
          <AccordionSummary
            expandIcon={<Image src='/expandmore.png' width={10} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Audit Trail: Viewing of Audit Trail</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* <Stack direction="column"
              justifyContent="flex-start"
              alignItems="flex-start">
              <Image src='/jaspos/audit.png' width={500} />

            </Stack> */}

            <Card sx={{p: 2}}>
              <Typography variant='h6'>
                {'1. To view the Audit Trail, go to "Audit Trail" module.'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/audittrail1.jpg' width={500} />
                </Stack>
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/audittrail2.jpg' width={500} />
                </Stack>
              </Typography>

            </Card>

          </AccordionDetails>
        </Accordion>

      </Container>
    </>
  )
}

export default Audit