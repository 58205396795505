import React from 'react'
import { Stack } from '@mui/material'
import Signup from '../Components/Steps/Signup'
import Header from '../Components/Header/Header'
import Login from '../Components/Steps/Login'
import Generic from '../Components/Steps/Generic'
import Category from '../Components/Steps/Category'
import ProductList from '../Components/Steps/ProductList'
import Inventory from '../Components/Steps/Inventory'
import Supplier from '../Components/Steps/Supplier'
import Customer from '../Components/Steps/Customer'
import OrderManagement from '../Components/Steps/OrderManagement'
import POSO from '../Components/Steps/POSO'
import Stock from '../Components/Steps/Stock'
import Billing from '../Components/Steps/Billing'
import Invoice from '../Components/Steps/Invoice'
import Analytics from '../Components/Steps/Analytics'
import Audit from '../Components/Steps/Audit'
import Badstock from '../Components/Steps/Badstock'



const Steps = () => {
  return (
    <div>
      <Stack spacing={1} justifyContent="center" alignItems="center">
        <Header />
        <Signup />
        <Login />
        <Generic />
        <Category />
        <ProductList />
        <Inventory />
        {/* <Badstock /> */}
        <OrderManagement />
        <POSO />
        <Stock />
        <Billing />
        <Invoice />
        <Analytics />
        <Supplier />
        <Customer />
        <Audit />
      </Stack>
    </div>
  )
}

export default Steps