import React from 'react'
import { 
  Fab, 
  Card, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Typography, 
  Stack,
  Container,
  Box,
  Button
} from '@mui/material';
import { Image } from 'mui-image'

const Signup = () => {
  return (
      <Container sx={{alignItems: 'center'}}>
        <Typography variant='h5' sx={{ my: 2, fontWeight: 'bold' }}>Click the Instruction to view </Typography>
        <Accordion sx={{color: '#05805f'}}>
          <AccordionSummary
            expandIcon={<Image src='/expandmore.png' width={10} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>SIGN UP: How to Register Account</Typography>
          </AccordionSummary>
          <AccordionDetails>
             {/* <Stack >
              <Image src='/steps/step-one.png' width={500} />
            </Stack>  */}

            <Stack sx={{p: 2}}>

            <Card sx={{p: 2}}>
              <Typography variant='h6'>
                {"1. A one-time registration Set Up for Administrator. Log your admin Account. Type on your browser."}
              </Typography>
              
              <Box sx={{p: 2}}>
              <Fab href='https://jas5-pos.8nergyitsolutions.com'
                variant="extended" color='info'>
                {"https://jas5-pos.8nergyitsolutions.com"}
              </Fab>
              </Box>

              <Typography variant='h6'>
                {"2. Go to User Management Module and click the User Management sub-module."}
                <Stack sx={{p: 2, alignItems: 'center' }}>
                  <Image src='manual_assets/UserManagement.jpg' width={300}/>
                </Stack> 
              </Typography>

              <Typography variant='h6'>
                {'3. Click the '} <Button variant='contained' color='success'>Add New User</Button> {' Button'}
              </Typography>

              <Typography variant='h6'>
                {'4. Fill in the provided information fields provided then click.'} <Button color='success' variant='contained'>Confirm Add</Button>
                  
              </Typography>

              <Typography variant='h6'>
                {"5. You can check the User Management table if you want to confirm and edit the account you created."}
              </Typography>

              </Card>
            </Stack>

          </AccordionDetails>
        </Accordion>
      </Container>
  )
}

export default Signup