import React from 'react'
import { Card, Accordion, AccordionSummary, AccordionDetails, Typography, Stack, Container, Button } from '@mui/material';
import { Image } from 'mui-image'

const Stock = () => {
  return (
    <>
      <Container >
        <Accordion sx={{color: '#05805f'}}>
          <AccordionSummary
            expandIcon={<Image src='/expandmore.png' width={10} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>ORDER MANAGEMENT: Stock Issuance and Stock Approval</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* <Stack direction="column"
              justifyContent="center"
              alignItems="flex-start">
              <Image src='/jaspos/Stock1.png' width={500} />
              <Image src='/jaspos/Stock2.png' width={500} />
            </Stack> */}

            <Card sx={{p: 2}}>
              <Typography variant='h6'>
                {'1. To view the Stock issuance, go to "Stock Issuance" module.'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/stockissuance1.jpg' width={500} />
                </Stack>
              </Typography>

              <Typography variant='h6'>
                {'2.  Click the "Three dot setting/Kebab Settings there is view and edit'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/stockissuance2.jpg' width={500} />
                </Stack>
              </Typography>

              <Typography variant='h6'>
                {'3. Click the "View" button, to see the receipt. You can also print and export for your own copy.'}
              </Typography>

              <Typography variant='h6'>
                {'4. Click "Edit" to add issued quantity.'}
              </Typography>

              <Typography variant='h6'>
                {'5. Click '}<Button variant='contained' color='success' disabled>Post To Stock Issuance</Button>{' button for posting it on Stock Approval, Click "Declined" button if there input error.'}
              </Typography>

              <Typography variant='h6'>
                {'6. To Approve the Stock Issuance, go to module, Click the "Three dot setting/Kebab Setting and you will see view and edit option.'}
              </Typography>

              <Typography variant='h6'>
                {'7. Click the '}<Button variant='contained' color='success'>Post To Billing</Button> {' button to post it on billing.'}
              </Typography>

            </Card>

          </AccordionDetails>
        </Accordion>

      </Container>
    </>
  )
}

export default Stock