import React from 'react'
import { Button, Card, Accordion, AccordionSummary, AccordionDetails, Typography, Stack, Container } from '@mui/material';
import { Image } from 'mui-image'

const Generic = () => {
  return (
    <>
      <Container >
        <Accordion sx={{color: '#05805f'}}>
          <AccordionSummary
            expandIcon={<Image src='/expandmore.png' width={10} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>GENERIC: Adding Generic</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* <Stack>
              <Image src='/steps/step-three.png' width={500} />
            </Stack> */}

            <Card sx={{p: 2}}>
              <Typography variant='h6'>
                {'1. Click the '} <Button variant='contained' color='success'>Add Generic</Button> {' to open the form for adding Generic.'}
              </Typography>

              <Typography variant='h6'>
                {'2. Input necessary information on the form provided.'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/generic1.jpg' width={500} />
                </Stack>
              </Typography>

              <Typography variant='h6'>
                {'3. Click the '} <Button variant='contained' color='success' >Confirm Add</Button>{' button to continue. There will be validation before you add the Generic so you can check if the information provided is corrrect.'}
              </Typography>
            </Card>

          </AccordionDetails>
        </Accordion>

      </Container>
    </>
  )
}

export default Generic