import React from 'react'
import { Card, Accordion, AccordionSummary, AccordionDetails, Typography, Stack, Container, Button } from '@mui/material';
import { Image } from 'mui-image'

const Billing = () => {
  return (
    <>
      <Container >
        <Accordion sx={{color: '#05805f'}}>
          <AccordionSummary
            expandIcon={<Image src='/expandmore.png' width={10} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant='h6' sx={{ fontWeight: 'bold' }}>ORDER MANAGEMENT: Billing and Billing Email Approval</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* <Stack direction="column"
              justifyContent="flex-start"
              alignItems="flex-start">
              <Image src='/jaspos/billing1.png' width={500} />
              <Image src='/jaspos/billing2.png' width={500} />
              <Image src='/jaspos/billing3.png' width={500} />
            </Stack> */}


            <Card sx={{p: 2}}>
              <Typography variant='h6'>
                {'1. To view the billing, go to "Billing" and you will see the data table.'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/billing1.jpg' width={500} />
                </Stack>
              </Typography>

              <Typography variant='h6'>
                {'2. Click the "Three dot setting/Kebab Settings and you will see the View, Edit Email and Send Email option.'}
                <Stack sx={{alignItems: 'center', p: 2}}>
                  <Image src='/manual_assets/billing2.jpg' width={500} />
                </Stack>
              </Typography>
              
              <Typography variant='h6'>
                {'3. Click "View" option to view the receipt. You can also print and export for your own copy.'}
              </Typography>
              
              <Typography variant='h6'>
                {'4. Click "Edit Email" option and fill up the form given. You can also click the "plus" button to input multiple email. Then Click '}<Button variant='contained' color='success'>Save</Button> {' to post on Billing Email Approval.'}
              </Typography>
              
              <Typography variant='h6'>
                {'5. To approve Billing Email, go to "Billing Email Approval" and then click the "Open" option, you can send the billing and Sales invoice on email provided. if you click "Close" you can no longer send this to any email.'}
              </Typography>

            </Card>

          </AccordionDetails>
        </Accordion>

      </Container>
    </>
  )
}

export default Billing